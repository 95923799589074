const en_rotateIcon = require("url:../../../assets/icons/rotation-icon.svg");
const en_toggleIcon = require("url:../../../assets/icons/white-toggle-arrow.svg");
const en_sustBackgroundImg = require("url:../../../assets/images/sust-bg.svg");
const en_indaloVideo =
	"https://indalo-institucional.s3.amazonaws.com/grupo-indalo.mp4";
	const en_SustPdf = require("url:../../../assets/Sustentabilidad-Indalo-2024-5.pdf");


customElements.define(
	"en-home-page",
	class en_initHomePage extends HTMLElement {
		connectedCallback() {
			this.render();
			this.addListeners();
		}

		render() {
			this.innerHTML = `
							<div class="container">
								<en-header-comp class="header"></en-header-comp>
							
								<div id="hero" class="hero">
									<div class="hero__slider-container">
										<en-hero-slider class="hidden">
											Construction, energy, mining and media.
										</en-hero-slider>
									</div>
									<div class="hero__scroll">
										<text-comp class="scroll__text" variant="hero" scheme="f-light" contains-html>
											Scroll
											<img src="${en_toggleIcon}"/>
										</text-comp>
									</div>
								</div>
								
								<div id="about-us" class="about-us">
									<span class="divider mobile"></span>
									<div class="about-us__content mobile">
										<title-comp class="hidden" variant="section montserrat bold" scheme="light">
											Our future, much to do
										</title-comp>
										<subtitle-comp class="hidden" variant="section montserrat bold" scheme="yellow">
											What is Grupo Indalo?
										</subtitle-comp>
										<text-comp class="hidden" variant="section" scheme="light">
											Grupo Indalo is a conglomerate of companies that has been investing in Argentina for more than twenty years and is committed to the development of the country, generating employment for thousands of workers throughout the country. 
										</text-comp>
										<text-comp class="hidden" variant="section" scheme="light">
											Grupo Indalo includes production and service companies in different industries, mainly in media, construction, energy and mining.
										</text-comp>
										<text-comp class="hidden" variant="section" scheme="light">
											It was established in 1999 and since then it has become one of the most prominent groups in Argentina with growth projections across the region.
										</text-comp>
									</div>

									<span class="divider xl mobile"></span>

									<div class="about-us__content mobile second-section">
										<title-comp class="hidden" variant="section montserrat bold" scheme="light">What we believe</title-comp>
										<subtitle-comp class="hidden" variant="section jakarta semibold" scheme="red">Mission</subtitle-comp>
										<text-slider dualSlider class="hidden"
										first-paragraph="Our mission is to contribute to the economic and social development of Argentina by investing in companies in different industries, generating employment and improving the quality of life of the population."
										second-paragraph="We aim to be leaders in each of our business areas, providing high quality products and services that meet the needs of our customers and generate a positive impact on society."
										>
										</text-slider>
										<subtitle-comp class="hidden" variant="section jakarta semibold" scheme="blue">Vision</subtitle-comp>
										<text-slider class="hidden"
										first-paragraph="We aim to consolidate the leadership of our business group in the country and to expand into the region in search of new strategic markets."
										second-paragraph="To be a key player in each of the industries in which we operate, providing innovative products and solutions that generate value for our customers and society in general."
										third-paragraph="In addition, we are committed to being a socially responsible company, promoting sustainability and environmental protection in each of our operations and contributing to the development of the communities in which we operate."
										>
										</text-slider>
										<subtitle-comp class="hidden" variant="section jakarta semibold" scheme="green">Values</subtitle-comp>
										<text-slider class="hidden" variant="last-item"
										first-title="Commitment:"
										first-paragraph="We are committed to providing innovative and quality products to our customers and to being a socially responsible group of companies that contributes to the sustainable development of the communities in which we operate."
										second-title="Innovation:"
										second-paragraph="We strive to be at the forefront of each of the markets in which we operate, investing in new technologies and fostering creativity and innovation in all our work teams."
										third-title="Excellence:"
										third-paragraph="We pursue excellence in everything we do, from business management to the quality of our products and services."
										>
										</text-slider>
										<span class="divider mobile bottom event"></span>
									</div>

									<div class="about-us__content desktop">
										<en-about-content class="hidden"></en-about-content>
									</div>

									<div class="about-us__media hidden">
										<img class="hidden about-us__media-icon" src="${en_rotateIcon}">
									<video-embed src="${en_indaloVideo}"></video-embed>
									</div>
								</div>

								<div id="companies" class="companies">
									<div class="companies__content mobile">
										<span class="divider mobile"></span>
										<title-comp class="hidden" variant="section medium margin-top jakarta extra-bold" scheme="dark">Our Group</title-comp>
										<div class="companies-cards">
											<en-companies-slider></en-companies-slider>
										</div> 
										<span class="divider dark mobile bottom"></span>
									</div>
									<div class="hidden companies__content desktop">
										<en-companies-content></en-companies-content>
									</div>
								</div>

								<div id="sust" class="sust">
									<div class="hidden sust-box">
										<div class="sust-box__pattern"></div>
										<div class="sust-box__content-wrapper">
											<div class="sust-box__content">
												<title-comp class="hidden" variant="section jakarta medium no-x-padding" scheme="light">Sustainability</title-comp>
												<div class="sust-box__subtitle">
													<subtitle-comp class="hidden" variant="montserrat medium" scheme="light"></subtitle-comp>
												</div>
												<a class="sust-box__link" href="${en_SustPdf}">
													<button class="hidden sust-box__button">See more</button>
												</a>
											</div>
										</div>
									</div>
								</div>

								<en-footer-comp></en-footer-comp>
							</div>
			`;

			const style = document.createElement("style");
			style.textContent = `

			.hidden {
				opacity: 0;
				filter: blur(5px);
				transform: translateY(-50px);
				transition: all .6s;
			}

			@media (prefers-reduced-motion) {
				.hidden {
					transition: none;
				}
			}

			.show {
				opacity: 1;
				filter: blur(0);
				transform: translateY(0);
			}

            .divider {
                margin-top: 3.8vh;
                width: 8vh;
                height: 1px;
                transform: rotate(90deg);
                border: 1px solid var(--white-color);
            }

			.divider.dark {
				border-color: var(--dark-color);
			}

            .divider.xl {
                width: 75px;
            }

            .divider.bottom {
                margin-top: 0;
                margin-bottom: 3.9vh;
				align-self: center;
            }

			@media (min-width: 768px) {
				.divider {
					display: none;
				}
			}
			
			@media (min-width: 630px) {
				.divider.mobile {
					display: none;
				}
			}

            .container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
				user-select: none;
            }

			.hero {
                width: 100%;
                height: 90vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

			@media (min-width: 500px) {
				.hero {
					justify-content: space-between;
					align-items: flex-start;
				}
			}
			
			@media (min-width: 768px) {
				.hero {
					height: 100vh;
				}
			}

			.hero__slider-container {
				margin: auto 0;
			}

			.hero__scroll {
				display: none;
			}

			@media (min-width: 768px) {
				.hero__scroll {
					display: initial;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					border-bottom: 1px solid var(--primary-color);
				}
			}

			.scroll__text {
				opacity: 0.8;
				transition: all .3s;
				animation: bounce 3s infinite;
			}

			.scroll__text:hover {
				cursor: pointer;
				opacity: 1;
			}

			.about-us {
                width: 100%;
                height: 100%;
				min-height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
				background-color: var(--bkgd-color);
                border-top: 1px solid var(--white-color);
            }

			@media (min-width: 630px) {
				.about-us {
					border-top: none;
				}
			}

			.about-us__content {
				width: 100%;
				height: 100%;
				padding: 35px 25px;
				padding-bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.about-us__content.second-section {
				align-items: flex-start;
				border-bottom: 1px solid var(--white-color);
			}	

			@media (min-width: 630px) {
				.about-us__content {
					padding: 0;
					padding-top: 9vh;
					border-bottom: 1px solid var(--white-color)
				}
			}

			.about-us__content.desktop {
				display: none;
			}

			@media (min-width: 630px) {
				.about-us__content.mobile {
					display: none;
				}
				
				.about-us__content.desktop {
					display: inherit;
				}
			}

			.about-us__media {
				width: 100%;
				height: 100%;
				padding: 25px 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 15px;
			}

			@media (min-width: 768px) {				
				.about-us__media {
					height: 80vh;
					padding: 50px 0;
				}
			}

			@media (min-width: 1280px) {
				.about-us__media {
					height: 90vh;
					padding: 60px 0;
				}
			}

			@media (min-width: 1440px) {
				.about-us__media {
					padding: 100px 0;
				}
			}

			@media (min-width: 1850px) {
				.about-us__media {
					padding: 110px 0;
				}
			}

			@media (min-width: 768px) {
				.about-us__media-icon {
					display: none;
				}
			}

            .companies {
                width: 100%;
                height: 100vh;
				min-height: 770px;
				margin-top: 3vh;
                display: flex;
                flex-direction: column;
				align-items: center;
                justify-content: flex-start;
				background-color: var(--white-color);
				border-bottom: 1px solid var(--black-color);
            }


			@media (min-width: 768px) {
				.companies {
					min-height: 180vh;
					margin: 0;
					border: none;
				}
			}

			@media (min-width: 768px) and (max-width: 1440px) and (max-height: 780px) {
                .companies {
                    height: 200vh;
                }
            }

            @media (min-width: 768px) and (max-width: 1440px) and (max-height: 700px) {
                .companies {
                    height: 225vh;
                }
            }

            @media (min-width: 768px) and (max-width: 1440px) and (max-height: 650px) {
                .companies {
                    height: 230vh;
                }
            }

			@media (min-width: 1440px) {
				.companies {
					min-height: 260vh;
				}
			}

			@media (min-width: 1440px) and (max-height: 780px) {
                .companies {
                    height: 275vh;
                }
            }

            @media (min-width: 1440px) and (max-height: 700px) {
                .companies {
                    height: 290vh;
                }
            }

			.companies__content {
				width: 100%;
                height: 100%;
				display: flex;
                flex-direction: column;
				align-items: center;
                justify-content: flex-start;
			}

			.companies__content.desktop {
				display: none;
			}

			@media (min-width: 768px) {
				.companies__content.mobile {
					display: none;
				}

				.companies__content.desktop {
					height: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
			}
			
			.content__left-side {
				height: 100%;
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}

			.companies-cards { 
				width: 100%;
				height: 100%;
			}

			.companies-cards.desktop {
				display: none;
			}

			@media (min-width: 630px) {
				.companies-cards.mobile {
					display: none;
				}

				.companies-cards.desktop {
					display: flex;
					justify-content: center;
					align-items: center;
				}	
			}

			.sust {
                width: 100%;
                height: 50vh;
				min-height: 425px;
                display: flex;
                justify-content: center;
				align-items: center;
				background-color: var(--white-color);
            }

			@media (min-width: 768px) {
				.sust {
					height: 70vh;
				}
			}

			.sust-box {
				width: 360px;
				height: 370px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				border: none;
				border-radius: 10px;
				background-color: var(--black-color);
			}

			@media (min-width: 768px) {
				.sust-box {
					height: 40vh;
					width: 70vw;
					flex-direction: row-reverse;
				}
			}

			.sust-box__pattern {
				width: 100%;
				height: 30%;
				background-position: top center;
				background-image: url(${en_sustBackgroundImg});
				background-size: 75%;
			}

			@media (min-width: 768px) {
				.sust-box__pattern {
					width: 50%;
					height: 100%;
					background-size: cover;
				}
			}

			.sust-box__content-wrapper {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			@media (min-width: 768px) {
				.sust-box__content-wrapper {
					width: 50%;
				}	
			}

			.sust-box__content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
			}

			@media (min-width: 768px) {
				.sust-box__content {
					width: 85%;
					align-items: flex-start;
					text-align: left;
				}
			}

			@media (min-width: 960px) {
				.sust-box__content {
					width: 70%;
				}
			}

			@media (min-width: 1440px) {
				.sust-box__content {
					width: 60%;
				}
			}

			.sust-box__subtitle {
				width: 100%;
				padding: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-top: 1px solid var(--white-color);
			}

			@media (min-width: 768px) {
				.sust-box__subtitle {
					width: 90%;
					padding: 10px 0;
					padding-bottom: 20px;
					border: none;
				}
			}

			@media (min-width: 1850px) {
				.sust-box__subtitle {
					padding: 15px 0;
					padding-bottom: 25px;
				}
			}

			.sust-box__link {
				text-decoration: none;
			}

			.sust-box__button {
				width: 110px;
				height: 35px;
				background-color: var(--white-color);
				border: none;
				border-radius: 22px;
				font: 500 10px var(--jakarta-font);
			}

			.sust-box__button:hover {
				opacity: 0.8;
				cursor: pointer;
			}

			@media (min-width: 768px) {
				.sust-box__button {					
					width: 130px;
					height: 40px;
					font-size: 15px;				
				}
			}

			@media (min-width: 960px) {
				.sust-box__button {
					border-radius: 30px;

				}
			}

			@media (min-width: 1280px) {
				.sust-box__button {					
					width: 165px;
					height: 50px;
					font-size: 18px;
				}
			}

			@keyframes bounce {
				0% {
				  transform: translateY(0);
				}
				25% {
				  transform: translateY(-10px);
				}
				50% {
				  transform: translateY(0);
				}
				100% {
					transform: translateY(0);	
				}
			  }
        `;
			this.appendChild(style);
		}

		addListeners() {
			const bodyEl = document.querySelector("body");
			const rootEl: HTMLElement = document.querySelector(".root");

			const scrollTextEl = this.querySelector(".scroll__text");
			const aboutUsEl = this.querySelector("#about-us");

			bodyEl.style.overflowY = "auto";
			rootEl.style.overflowX = "initial";

			scrollTextEl.addEventListener("click", () => {
				aboutUsEl.scrollIntoView({ behavior: "smooth" });
			});

			window.addEventListener("beforeunload", (e) => {
				e.preventDefault();
				window.scrollTo(0, 0);
			});

			window.addEventListener("load", () => {
				bodyEl.style.overflowY = "auto";
			});

			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("show");
					} else {
						entry.target.classList.remove("show");
					}
				});
			});

			const hiddenElements = this.querySelectorAll(".hidden");
			hiddenElements.forEach((el) => observer.observe(el));
		}
	}
);
