const rotateIcon = require("url:../../assets/icons/rotation-icon.svg");
const toggleIcon = require("url:../../assets/icons/white-toggle-arrow.svg");
const sustBackgroundImg = require("url:../../assets/images/sust-bg.svg");
const SustPdf = require("url:../../assets/Memorias-2024.pdf");
const indaloVideo =
	"https://indalo-institucional.s3.amazonaws.com/grupo-indalo.mp4";

customElements.define(
	"home-page",
	class initHomePage extends HTMLElement {
		connectedCallback() {
			this.render();
			this.addListeners();
		}

		render() {
			this.innerHTML = `
            <div class="container">
                <header-comp class="header"></header-comp>
            
				<div id="hero" class="hero">
					<div class="hero__slider-container">
						<hero-slider class="hidden">
						Construcción, energía, minería y medios de comunicación.
						</hero-slider>
					</div>
					<div class="hero__scroll">
						<text-comp class="scroll__text" variant="hero" scheme="f-light" contains-html>
							Scroll
							<img src="${toggleIcon}"/>
						</text-comp>
					</div>
				</div>
				
				<div id="about-us" class="about-us">
                	<span class="divider mobile"></span>
                    <div class="about-us__content mobile">
                        <title-comp class="hidden" variant="section montserrat bold" scheme="light">
							Nuestro futuro, mucho por hacer
						</title-comp>
                        <subtitle-comp class="hidden" variant="section montserrat bold" scheme="yellow">
							¿Qué es el Grupo Indalo?
						</subtitle-comp>
                        <text-comp class="hidden" variant="section" scheme="light">
                        	El Grupo Indalo es un conglomerado de empresas que invierte en la Argentina desde hace más de veinte años y que apuesta por el desarrollo del país generando empleo para miles de trabajadores y trabajadoras a lo largo de todo el territorio nacional.
                        </text-comp>
                        <text-comp class="hidden" variant="section" scheme="light">
                        	Está integrado por empresas de producción y servicios de diferentes rubros, principalmente en medios de comunicación, construcción, energía y minería.
                        </text-comp>
                        <text-comp class="hidden" variant="section" scheme="light">
                        	Fue creado en 1999 y desde entonces se convirtió en uno de los grupos más importantes de la Argentina con proyecciones de crecimiento en toda la región.
                        </text-comp>
                    </div>

                    <span class="divider xl mobile"></span>

                    <div class="about-us__content mobile second-section">
                        <title-comp class="hidden" variant="section montserrat bold" scheme="light">Lo que creemos</title-comp>
                        <subtitle-comp class="hidden" variant="section jakarta semibold" scheme="red">Misión</subtitle-comp>
                        <text-slider dualSlider class="hidden"
						first-paragraph="Contribuir al desarrollo económico y social de la Argentina a través de la inversión en empresas de diversos sectores, generando empleo y mejorando la calidad de vida de la población."
						second-paragraph="Buscamos ser líderes en cada una de nuestras áreas de negocio, brindando productos y servicios de alta calidad que satisfagan las necesidades de nuestros clientes y generen un impacto positivo en la sociedad."
						>
                        </text-slider>
                        <subtitle-comp class="hidden" variant="section jakarta semibold" scheme="blue">Visión</subtitle-comp>
                        <text-slider class="hidden"
						first-paragraph="Apuntamos a consolidar el liderazgo de nuestro grupo empresarial en el país y a proyectarnos hacia la región en busca de nuevos mercados estratégicos."
						second-paragraph="Ser referentes en cada uno de los sectores en los que participamos, brindando productos y soluciones innovadoras que generen valor a nuestros clientes y a la sociedad."
						third-paragraph="Además, nos comprometemos a ser una empresa socialmente responsable, promoviendo la sustentabilidad y el cuidado del medio ambiente en cada una de nuestras operaciones y contribuyendo al desarrollo de las comunidades en las que operamos."
						>
                        </text-slider>
                        <subtitle-comp class="hidden" variant="section jakarta semibold" scheme="green">Valores</subtitle-comp>
                        <text-slider class="hidden" variant="last-item"
						first-title="Compromiso:"
						first-paragraph="Nos comprometemos a brindar productos innovadores y de calidad a nuestros clientes y a ser un grupo de empresas socialmente responsable que contribuya al desarrollo sostenible de las comunidades en las que opera."
						second-title="Innovación:"
						second-paragraph="Nos esforzamos por estar a la vanguardia de cada uno de los mercados en los que participamos, invirtiendo en nuevas tecnologías y fomentando la creatividad y la innovación en todos nuestros equipos de trabajo."
						third-title="Excelencia:"
						third-paragraph="Buscamos la excelencia en todo lo que hacemos, desde la gestión empresarial hasta en la calidad de nuestros productos y servicios."
						>
                        </text-slider>
						<span class="divider mobile bottom event"></span>
                    </div>

					<div class="about-us__content desktop">
						<about-content class="hidden"></about-content>
					</div>

					<div class="about-us__media hidden">
						<img class="hidden about-us__media-icon" src="${rotateIcon}">
						<video-embed src="${indaloVideo}"></video-embed>
					</div>
				</div>

				<div id="companies" class="companies">
					<div class="companies__content mobile">
						<span class="divider mobile"></span>
						<title-comp class="hidden" variant="section medium margin-top jakarta extra-bold" scheme="dark">Nuestro grupo</title-comp>
						<div class="companies-cards">
							<companies-slider></companies-slider>
						</div>
						<span class="divider dark mobile bottom"></span>
					</div>
					<div class="hidden companies__content desktop">
						<companies-content></companies-content>
					</div>
				</div>

                <div id="sust" class="sust">
					<div class="hidden sust-box">
						<div class="sust-box__pattern"></div>
						<div class="sust-box__content-wrapper">
							<div class="sust-box__content">
								<title-comp class="hidden" variant="section jakarta medium no-x-padding" scheme="light">Sustentabilidad</title-comp>
								<div class="sust-box__subtitle">
									<subtitle-comp class="hidden" variant="montserrat medium" scheme="light"></subtitle-comp>
								</div>
								<a class="sust-box__link" href="${SustPdf}" target="_blank">
								<button class="hidden sust-box__button">Ver más</button>
								</a>
							</div>
						</div>
					</div>
                </div>

				<footer-comp></footer-comp>
			</div>
        	`;

			const style = document.createElement("style");
			style.textContent = `

			.hidden {
				opacity: 0;
				filter: blur(5px);
				transform: translateY(-50px);
				transition: all .6s;
			}

			@media (prefers-reduced-motion) {
				.hidden {
					transition: none;
				}
			}

			.show {
				opacity: 1;
				filter: blur(0);
				transform: translateY(0);
			}

            .divider {
                margin-top: 3.8vh;
                width: 8vh;
                height: 1px;
                transform: rotate(90deg);
                border: 1px solid var(--white-color);
            }

			.divider.dark {
				border-color: var(--dark-color);
			}

            .divider.xl {
                width: 75px;
            }

            .divider.bottom {
                margin-top: 0;
                margin-bottom: 3.9vh;
				align-self: center;
            }

			@media (min-width: 768px) {
				.divider {
					display: none;
				}
			}
			
			@media (min-width: 630px) {
				.divider.mobile {
					display: none;
				}
			}

            .container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
				user-select: none;
            }

			.hero {
                width: 100%;
                height: 90vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

			@media (min-width: 500px) {
				.hero {
					justify-content: space-between;
					align-items: flex-start;
				}
			}
			
			@media (min-width: 768px) {
				.hero {
					height: 100vh;
				}
			}

			.hero__slider-container {
				margin: auto 0;
			}

			.hero__scroll {
				display: none;
			}

			@media (min-width: 768px) {
				.hero__scroll {
					display: initial;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					border-bottom: 1px solid var(--primary-color);
				}
			}

			.scroll__text {
				opacity: 0.8;
				transition: all .3s;
				animation: bounce 3s infinite;
			}

			.scroll__text:hover {
				cursor: pointer;
				opacity: 1;
			}

			.about-us {
                width: 100%;
                height: 100%;
				min-height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
				background-color: var(--bkgd-color);
                border-top: 1px solid var(--white-color);
            }

			@media (min-width: 630px) {
				.about-us {
					border-top: none;
				}
			}

			.about-us__content {
				width: 100%;
				height: 100%;
				padding: 35px 25px;
				padding-bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.about-us__content.second-section {
				align-items: flex-start;
				border-bottom: 1px solid var(--white-color);
			}	

			@media (min-width: 630px) {
				.about-us__content {
					padding: 0;
					padding-top: 9vh;
					border-bottom: 1px solid var(--white-color)
				}
			}

			.about-us__content.desktop {
				display: none;
			}

			@media (min-width: 630px) {
				.about-us__content.mobile {
					display: none;
				}
				
				.about-us__content.desktop {
					display: inherit;
				}
			}

			.about-us__media {
				width: 100%;
				height: 100%;
				padding: 25px 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 15px;
			}

			@media (min-width: 768px) {				
				.about-us__media {
					height: 80vh;
					padding: 50px 0;
				}
			}

			@media (min-width: 1280px) {
				.about-us__media {
					height: 90vh;
					padding: 60px 0;
				}
			}

			@media (min-width: 1440px) {
				.about-us__media {
					padding: 100px 0;
				}
			}

			@media (min-width: 1850px) {
				.about-us__media {
					padding: 110px 0;
				}
			}

			@media (min-width: 768px) {
				.about-us__media-icon {
					display: none;
				}
			}

            .companies {
                width: 100%;
                height: 100vh;
				min-height: 770px;
				margin-top: 3vh;
                display: flex;
                flex-direction: column;
				align-items: center;
                justify-content: flex-start;
				background-color: var(--white-color);
				border-bottom: 1px solid var(--black-color);
            }


			@media (min-width: 768px) {
				.companies {
					min-height: 180vh;
					margin: 0;
					border: none;
				}
			}

			@media (min-width: 768px) and (max-width: 1440px) and (max-height: 780px) {
                .companies {
                    height: 200vh;
                }
            }

            @media (min-width: 768px) and (max-width: 1440px) and (max-height: 700px) {
                .companies {
                    height: 225vh;
                }
            }

            @media (min-width: 768px) and (max-width: 1440px) and (max-height: 650px) {
                .companies {
                    height: 230vh;
                }
            }

			@media (min-width: 1440px) {
				.companies {
					min-height: 260vh;
				}
			}

			@media (min-width: 1440px) and (max-height: 780px) {
                .companies {
                    height: 275vh;
                }
            }

            @media (min-width: 1440px) and (max-height: 700px) {
                .companies {
                    height: 290vh;
                }
            }

			.companies__content {
				width: 100%;
                height: 100%;
				display: flex;
                flex-direction: column;
				align-items: center;
                justify-content: flex-start;
			}

			.companies__content.desktop {
				display: none;
			}

			@media (min-width: 768px) {
				.companies__content.mobile {
					display: none;
				}

				.companies__content.desktop {
					height: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
			}
			
			.content__left-side {
				height: 100%;
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}

			.companies-cards { 
				width: 100%;
				height: 100%;
			}

			.companies-cards.desktop {
				display: none;
			}

			@media (min-width: 630px) {
				.companies-cards.mobile {
					display: none;
				}

				.companies-cards.desktop {
					display: flex;
					justify-content: center;
					align-items: center;
				}	
			}

			.sust {
                width: 100%;
                height: 50vh;
				min-height: 425px;
                display: flex;
                justify-content: center;
				align-items: center;
				background-color: var(--white-color);
            }

			@media (min-width: 768px) {
				.sust {
					height: 70vh;
				}
			}

			.sust-box {
				width: 360px;
				height: 370px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				border: none;
				border-radius: 10px;
				background-color: var(--black-color);
			}

			@media (min-width: 768px) {
				.sust-box {
					height: 40vh;
					width: 70vw;
					flex-direction: row-reverse;
				}
			}

			.sust-box__pattern {
				width: 100%;
				height: 30%;
				background-position: top center;
				background-image: url(${sustBackgroundImg});
				background-size: 75%;
			}

			@media (min-width: 768px) {
				.sust-box__pattern {
					width: 50%;
					height: 100%;
					background-size: cover;
				}
			}

			.sust-box__content-wrapper {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			@media (min-width: 768px) {
				.sust-box__content-wrapper {
					width: 50%;
				}	
			}

			.sust-box__content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
			}

			@media (min-width: 768px) {
				.sust-box__content {
					width: 85%;
					align-items: flex-start;
					text-align: left;
				}
			}

			@media (min-width: 960px) {
				.sust-box__content {
					width: 70%;
				}
			}

			@media (min-width: 1440px) {
				.sust-box__content {
					width: 60%;
				}
			}

			.sust-box__subtitle {
				width: 100%;
				padding: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-top: 1px solid var(--white-color);
			}

			@media (min-width: 768px) {
				.sust-box__subtitle {
					width: 90%;
					padding: 10px 0;
					padding-bottom: 20px;
					border: none;
				}
			}

			@media (min-width: 1850px) {
				.sust-box__subtitle {
					padding: 15px 0;
					padding-bottom: 25px;
				}
			}

			.sust-box__link {
				text-decoration: none;
			}

			.sust-box__button {
				width: 110px;
				height: 35px;
				background-color: var(--white-color);
				border: none;
				border-radius: 22px;
				font: 500 10px var(--jakarta-font);
			}

			.sust-box__button:hover {
				opacity: 0.8;
				cursor: pointer;
			}

			@media (min-width: 768px) {
				.sust-box__button {					
					width: 130px;
					height: 40px;
					font-size: 15px;				
				}
			}

			@media (min-width: 960px) {
				.sust-box__button {
					border-radius: 30px;

				}
			}

			@media (min-width: 1280px) {
				.sust-box__button {					
					width: 165px;
					height: 50px;
					font-size: 18px;
				}
			}

			@keyframes bounce {
				0% {
				  transform: translateY(0);
				}
				25% {
				  transform: translateY(-10px);
				}
				50% {
				  transform: translateY(0);
				}
				100% {
					transform: translateY(0);	
				}
			  }
        `;
			this.appendChild(style);
		}

		addListeners() {
			const bodyEl = document.querySelector("body");
			const rootEl: HTMLElement = document.querySelector(".root");

			const scrollTextEl = this.querySelector(".scroll__text");
			const aboutUsEl = this.querySelector("#about-us");

			bodyEl.style.overflowY = "auto";
			rootEl.style.overflowX = "initial";

			scrollTextEl.addEventListener("click", () => {
				aboutUsEl.scrollIntoView({ behavior: "smooth" });
			});

			window.addEventListener("beforeunload", (e) => {
				e.preventDefault();
				window.scrollTo(0, 0);
			});

			window.addEventListener("load", () => {
				bodyEl.style.overflowY = "auto";
			});

			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("show");
					} else {
						entry.target.classList.remove("show");
					}
				});
			});

			const hiddenElements = this.querySelectorAll(".hidden");
			hiddenElements.forEach((el) => observer.observe(el));
		}
	}
);
